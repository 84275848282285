.react-calendar {
    width: 100%;
    max-width: 100%;
    background: #1a1a1a;
    border: 1px solid #1a1a1a;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
    color: white;
    position: relative;
  }
  
  .react-calendar__navigation {
    display: flex;
    justify-content: space-between;
    align-items: center; /* Alinează vertical butoanele */
    background-color: #1a1a1a;
    color: white;
    padding: 0 10px; /* Adaugă padding pentru a aduce butoanele în interior */
  }
  
  .react-calendar__navigation button {
    min-width: 44px;
    background: none;
    border: none;
    color: white;
    font-size: 16px;
    margin: 1em 0; /* Margin vertical pentru a alinia corect */
  }
  
  .react-calendar__tile {
    max-width: 100%;
    background: none;
    text-align: center;
    line-height: 1.2em;
    padding: 0.75em;
    background-color: #1a1a1a;
    color: white;
    border-radius: 5px;
    position: relative;
    overflow: hidden; /* Pentru a ascunde textul care depășește */
  }
  
  .react-calendar__tile--now {
    background: #fffa90 !important;
    color: black;
  }
  
  .react-calendar__tile--active {
    background: #007bff !important;
    color: white;
  }
  
  .react-calendar__tile--hasActive {
    background: #003366;
  }
  
  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    background: #0066cc;
  }
  
  .react-calendar__month-view__days__day--weekend {
    color: red;
  }
  
  .react-calendar__tile--now,
  .react-calendar__tile--hasActive,
  .react-calendar__tile--active {
    border: none;
    border-radius: 5px;
  }
  
  .react-calendar__tile--meeting {
    background-color: rgba(0, 123, 255, 0.7);
    color: white;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    position: absolute;
    top: 5px;
    right: 5px;
  }
  
  .react-calendar__navigation__label {
    color: white;
  }
  
  .react-calendar__navigation button:enabled:hover,
  .react-calendar__navigation button:enabled:focus {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 50%;
  }
  
  .react-calendar__navigation button[disabled] {
    background-color: #333;
  }
  
  .react-calendar__tile--hover {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 5px;
  }
  