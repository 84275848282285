@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
  /* Show scrollbar */
  .scrollbar::-webkit-scrollbar {
    display: block;
  }
  .scrollbar {
    -ms-overflow-style: auto;  /* IE and Edge */
    scrollbar-width: auto;  /* Firefox */
  }
}

/* .neon-text {
  text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 20px #ff00ff, 0 0 30px #ff00ff, 0 0 40px #ff00ff, 0 0 55px #ff00ff, 0 0 75px #ff00ff;
} */

.neon-border {
  border: 2px solid #ff0022;
  box-shadow: 0 0 5px #ff003c, 0 0 10px #ff004c, 0 0 20px #ff0055, 0 0 30px #ff002b, 0 0 40px #ff0022, 0 0 55px #ff004c, 0 0 75px #ff00ff;
}

@keyframes pulse {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

.animate-pulse {
  animation: pulse 2s infinite;
}

.status-online {
  border-color: #38a169; /* verde */
}

.status-ocupat {
  border-color: #ecc94b; /* galben */
}

.status-indisponibil {
  border-color: #e53e3e; /* roșu */
}

.status-online .status-indicator {
  background-color: #38a169; /* verde */
}

.status-ocupat .status-indicator {
  background-color: #ecc94b; /* galben */
}

.status-indisponibil .status-indicator {
  background-color: #e53e3e; /* roșu */
}

@keyframes pulse {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

.animate-pulse {
  animation: pulse 2s infinite;
}

.border-online {
  border-color: #38a169; /* verde */
}

.border-ocupat {
  border-color: #ecc94b; /* galben */
}

.border-indisponibil {
  border-color: #e53e3e; /* roșu */
}


